export const KokoLogo = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 56"
            xmlSpace="preserve"
        >
            <path
                d="M96.68,21.32c-1.91-2.05-4.46-3.18-7.16-3.18c-6.11,0-11.49,5.82-11.99,12.96c-0.27,3.87,0.93,7.54,3.28,10.06
	c1.91,2.06,4.46,3.19,7.16,3.19c6.11,0,11.49-5.82,11.99-12.96v-0.01C100.23,27.52,99.03,23.85,96.68,21.32z M95.96,31.11
	c-0.36,5.09-3.94,9.23-7.98,9.23c-1.6,0-3.06-0.66-4.23-1.91c-1.6-1.71-2.4-4.28-2.21-7.04c0.36-5.09,3.94-9.23,7.98-9.23
	c1.6,0,3.05,0.66,4.22,1.9C95.34,25.78,96.15,28.34,95.96,31.11z M23.78,18.56c0.69,0.87,0.56,2.13-0.31,2.82l-11.44,9.2
	c0.7,2.79,3.11,9.78,9.51,9.78c1.11,0,2.01,0.9,2.01,2.01c0,1.11-0.9,2.01-2.01,2.01c-8.99,0-12.91-9.33-13.72-14.26
	c-0.12-0.71,0.16-1.44,0.72-1.89l12.4-9.97C21.82,17.57,23.08,17.7,23.78,18.56z M13.34,5.11L3.96,42.86
	c-0.22,0.91-1.05,1.52-1.95,1.52c-0.16,0-0.32-0.02-0.49-0.06c-1.08-0.27-1.73-1.36-1.46-2.44L9.44,4.14
	c0.26-1.07,1.37-1.71,2.43-1.46C12.95,2.94,13.6,4.03,13.34,5.11z M47.54,31.39c0.16-2.25-0.23-4.39-1-6.32
	c1.06,0.19,2.18,0.34,3.36,0.34c2.31,0,4.45-0.42,6.41-1.14c-2.19,8.71-4.4,17.58-4.4,17.58c-0.27,1.08,0.39,2.17,1.46,2.44
	c0.16,0.04,0.32,0.06,0.49,0.06c0.9,0,1.72-0.61,1.95-1.52c0,0,2.85-11.44,5.27-21.05C67.46,17.34,71.15,10,71.65,6.49
	c0.28-1.96-0.15-3.71-1.2-4.93C69.57,0.55,68.36,0,67.02,0c-4.55,0-5.91,5.3-6.81,8.81c-0.42,1.64-1.44,5.7-2.64,10.45
	c-2.2,1.3-4.76,2.14-7.67,2.14c-4.34,0-6.01-0.87-8.18-2.07c-1.3-0.72-2.99-1.19-4.61-1.19c-6.11,0-11.49,5.82-11.99,12.96
	c-0.27,3.87,0.92,7.54,3.28,10.06c1.92,2.06,4.46,3.19,7.16,3.19C41.67,44.35,47.05,38.54,47.54,31.39z M64.1,9.8
	c0.99-3.87,2.09-5.91,3.12-5.62c0.51,0.14,0.61,0.6,0.45,1.75c-0.19,1.32-1.8,5.21-4.81,8.76C63.42,12.48,63.86,10.74,64.1,9.8z
 M29.12,31.39c0.36-5.09,3.93-9.23,7.98-9.23c1.6,0,3.06,0.66,4.23,1.91c1.6,1.71,2.4,4.28,2.21,7.04
	c-0.36,5.09-3.93,9.23-7.98,9.23c-1.6,0-3.06-0.66-4.22-1.91C29.74,36.72,28.93,34.15,29.12,31.39z M75.91,18.56
	c0.7,0.87,0.56,2.13-0.3,2.82l-11.44,9.2c0.71,2.79,3.11,9.78,9.51,9.78c1.11,0,2.01,0.9,2.01,2.01c0,1.11-0.9,2.01-2.01,2.01
	c-9,0-12.9-9.33-13.71-14.26c-0.12-0.71,0.16-1.44,0.72-1.89l12.4-9.97C73.95,17.56,75.21,17.7,75.91,18.56z M67.57,53.99
	c0,1.11-0.9,2.01-2.01,2.01H33.64c-1.11,0-2.01-0.9-2.01-2.01s0.9-2.01,2.01-2.01h31.92C66.67,51.98,67.57,52.88,67.57,53.99z"
            />
        </svg>
    )
}

export const KokoGuy = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 555.05 399.97"
            xmlSpace="preserve"
        >
            <path
                d="M321,359.65l13.95-17.57c-7.47-15.61-23.86-51.45-33.61-72.85C306.81,292.63,316.1,333.21,321,359.65z
 M156.53,119.55C108.98,108.7,67.32,89.43,50.68,72.53c-0.67-0.68-1.72-0.79-2.49-0.25c-0.48,0.33-0.78,0.84-0.83,1.42
	c-0.05,0.58,0.14,1.14,0.56,1.55l7.95,7.99c0.87,0.86,0.95,2.2,0.2,3.17s-2.07,1.23-3.12,0.61l-12.94-7.6
	c-0.84-0.49-1.88-0.3-2.49,0.45l-1.49,1.84c1.03,0.57,2.09,1.15,3.2,1.74C63.59,96.36,108.7,112.03,156.53,119.55z M465.47,91.49
	c-30.13-16.71-55.13-23.35-77.19-29.22c-18.69-4.97-36.35-9.68-54.02-19.64c-4.24,10.44-13.73,18.4-25.39,20.94
	c2.8,4.99,8.45,16.98,3.06,24.72C292,116.9,253.84,127.28,211.5,127.57c-0.1,0.02-0.19,0.06-0.29,0.06c-0.04,0-0.08,0-0.12,0
	c-0.24-0.02-0.49-0.04-0.73-0.05c-5.03,0.01-10.12-0.11-15.25-0.37c-0.14,0.03-0.27,0.06-0.41,0.06c-0.15,0-0.29-0.07-0.44-0.11
	c-60.77-3.21-126.07-24.23-156.76-40.48c-4.71-2.49-8.79-4.9-12.12-7.15c-0.35-0.24-0.84-0.16-1.13,0.17l-0.46,0.54
	c-0.31,0.36-0.27,0.9,0.08,1.21c3.64,3.23,10.58,9.7,10.65,9.76c0.66,0.61,0.77,1.62,0.26,2.36c-0.51,0.75-1.48,1.01-2.29,0.62
	c0,0-10.55-5.03-14.75-7.07c-0.35-0.16-0.77-0.09-1.03,0.2l-0.89,0.99c-0.22,0.24-0.24,0.52-0.22,0.66
	c0.01,0.14,0.07,0.41,0.33,0.62l11.55,9.05c0.89,0.71,1.12,1.92,0.53,2.9c-0.59,0.97-1.77,1.35-2.8,0.88l-16.55-6.6
	c-0.37-0.16-0.81-0.05-1.05,0.28l-0.6,0.88c-0.44,0.59,0.27,1.3,0.35,1.38c5.17,4.97,12.84,13.81,13.17,14.18
	c0.62,0.71,0.59,1.78-0.06,2.45c-0.65,0.68-1.71,0.75-2.45,0.17c-0.12-0.1-12.2-9.66-16.81-11.6c-0.34-0.14-0.73-0.06-0.98,0.21
	c-0.26,0.29-0.31,0.71-0.11,1.04l5.51,7.63c3.25,4.44,19.33,21.57,38.03,35.59c20.03,15.02,45.67,31.11,118.26,48.11
	c1.64-14.92,6.46-29.05,16.01-41.79c17.07-22.76,43.41-25.89,69.34-25.89c27.7,0,54.18,1.86,97.87,24.71
	c42.83,22.41,107.8,93.06,108.45,93.76c0.68,0.74,0.63,1.9-0.11,2.58c-0.75,0.68-1.9,0.63-2.58-0.11
	c-0.65-0.71-65.18-70.89-107.45-93c-42.96-22.47-68.97-24.3-96.18-24.3c-24.98,0-50.32,2.96-66.42,24.44
	c-24.42,32.56-16.52,75.23-4.34,116.5c19.63,66.53,34.93,113.17,35.08,113.63c0.2,0.61,0.07,1.27-0.35,1.75
	c-0.41,0.48-1.05,0.72-1.68,0.61c-0.45-0.07-8.98-1.31-15.13,4.97c-1.43,1.46-0.37,3.94,1.68,3.94h79.31
	c23.5,0,44.63-1.13,45.2-14.76c0.13-3.04-0.84-9.93-2.47-19.03c0-0.02-0.01-0.04-0.01-0.07c-6.66-37.18-24.41-111.27-24.63-112.19
	c-0.22-0.91,0.29-1.84,1.18-2.15c0.89-0.31,1.87,0.11,2.25,0.97c0.36,0.79,35.84,78.96,43.68,94.64l0.6,1.2
	c7.96,15.99,26.61,53.42,74.59,53.42h83.06c16.99,0,35.75-8.29,42.72-18.87c8.8-13.37,13.46-44.46,13.46-89.91
	c0-37.29,0-88.37-12.43-117.16C527.4,138.85,502.17,111.84,465.47,91.49z M201.73,123.19c3.27,0.33,6.47,0.6,9.58,0.79
	c21.26-0.15,43.13-2.87,61.82-10.45c-4.45-0.52-10.5-1.1-16.33-1.28C239.73,111.72,220.11,112.74,201.73,123.19z M268.69,30.41
	C268.69,13.64,283.12,0,300.86,0s32.17,13.64,32.17,30.41c0,16.76-14.43,30.41-32.17,30.41S268.69,47.17,268.69,30.41z
 M301.98,23.16c0.45-0.22,0.94-0.35,1.47-0.35c1.87,0,3.38,1.51,3.38,3.38c0,1.62-1.14,2.97-2.66,3.3c1.17,0.58,2.59,0.92,4.14,0.92
	c3.95,0,7.15-2.22,7.15-4.95c0-2.74-3.2-4.95-7.15-4.95C305.56,20.5,303.17,21.58,301.98,23.16z M277.71,22.81
	c1.87,0,3.38,1.51,3.38,3.38c0,1.62-1.14,2.97-2.66,3.3c1.17,0.58,2.59,0.92,4.14,0.92c3.95,0,7.15-2.22,7.15-4.95
	c0-2.74-3.2-4.95-7.15-4.95c-2.75,0-5.14,1.08-6.33,2.66C276.68,22.94,277.17,22.81,277.71,22.81z"
            />
        </svg>
    )
}
